import { graphql, useStaticQuery } from 'gatsby';

const useExitIntentData = () => {
  const { datoCmsExitIntentComponent } = useStaticQuery(graphql`
    query ExitIntentComponent {
      datoCmsExitIntentComponent {
        ...datoCmsExitIntentComponent
      }
    }
  `);
  return datoCmsExitIntentComponent;
};

export default useExitIntentData;
